import { createContext, useContext, ReactNode } from 'react';
import { useEditor, EditorContent, ReactNodeViewRenderer } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Link } from '@tiptap/extension-link';
import { Image } from '@tiptap/extension-image';
import { Table } from '@tiptap/extension-table';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import { cn } from '@/lib/utils';
import { getOutput } from '@/components/minimal-tiptap/utils';
import { ImageViewBlock } from '@/components/minimal-tiptap/components/image/image-view-block';
import { LinkBubbleMenu } from '@/components/minimal-tiptap/components/bubble-menu/link-bubble-menu';
import { ImageBubbleMenu } from '@/components/minimal-tiptap/components/bubble-menu/image-bubble-menu';
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey, TextSelection } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

const LockedComponent = Extension.create({
  addGlobalAttributes() {
    return [
      {
        // Apply to all types
        types: ['paragraph', 'heading', 'listItem', 'table', 'tableRow', 'tableCell', 'tableHeader'],
        // … with those attributes
        attributes: {
          'data-locked': {
            default: null,
            renderHTML: (attributes) => {
              if (attributes['data-locked']) {
                return { 'data-locked': attributes['data-locked'] };
              }
              return {};
            },
            parseHTML: (element) => element.getAttribute('data-locked') || null
          }
        }
      }
    ];
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('lockedComponent'),
        filterTransaction: (transaction, state) => {
          const { steps } = transaction;

          for (let i = 0; i < steps.length; i++) {
            const step = steps[i];
            let blocked = false;

            step.getMap().forEach((oldStart, oldEnd) => {
              state.doc.nodesBetween(oldStart, oldEnd, (node) => {
                if (node.attrs['data-locked']) {
                  blocked = true;
                  return false; // Stop iteration
                }
              });
            });

            if (blocked) {
              return false; // Block the transaction
            }
          }

          return true; // Allow the transaction
        },
        props: {
          decorations: ({ doc, selection }) => {
            const decorations: Decoration[] = [];

            doc.descendants((node, pos) => {
              if (node.attrs['data-locked']) {
                const decoration = Decoration.node(pos + 1, pos + node.nodeSize - 2, {
                  contenteditable: 'false'
                });
                decorations.push(decoration);

                // Move cursor to the end of the locked node if it's within the node and not already at the end or beginning
                if (
                  selection.from === selection.to &&
                  selection.from >= pos &&
                  selection.from < pos + node.nodeSize
                ) {
                  const endPos = pos + node.nodeSize;

                  const highlightDecoration = Decoration.node(
                    pos,
                    pos + node.nodeSize,
                    {
                      class: ''
                    }
                  );

                  decorations.push(highlightDecoration);

                  if (selection.to !== endPos && selection.from !== pos) {
                    const cursorPos = selection.from < (pos + node.nodeSize / 2) ? pos : endPos;
                    const transaction = this.editor.state.tr.setSelection(
                      TextSelection.create(this.editor.state.doc, cursorPos)
                    );
                    this.editor.view.dispatch(transaction);
                  }
                }
              }
            });

            return DecorationSet.create(doc, decorations);
          }
        }
      })
    ];
  }
});

interface LockedEditorProviderProps {
  children: ReactNode;
  value: string;
  onValueChange: (value: string) => void;
}

const EditorContext = createContext<ReturnType<typeof useEditor> | null>(null);

const LockedEditorProvider = ({
  children,
  value,
  onValueChange
}: LockedEditorProviderProps) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      LockedComponent,
      Image.extend({
        addNodeView() {
          return ReactNodeViewRenderer(ImageViewBlock);
        }
      }),
      Link.configure({
        openOnClick: false
      }),
      Table.configure({
        resizable: true
      }),
      TableRow.configure({
        HTMLAttributes: {
          class: 'border prose-p:my-0 prose-p:mx-2'
        }
      }),
      TableHeader.configure({
        HTMLAttributes: {
          class: 'border prose-p:my-0 prose-p:mx-1'
        }
      }),
      TableCell.configure({
        HTMLAttributes: {
          class: 'border prose-p:my-0 prose-p:mx-1'
        }
      })
    ],
    editorProps: {
      attributes: {
        class: 'prose mx-auto focus:outline-none max-w-none'
      }
    },
    onUpdate: (props) => {
      onValueChange(getOutput(props.editor, 'html'));
    },
    content: value,
    editable: true,
    onCreate: ({ editor }) => {
      if (value) {
        editor.chain().setContent(value).run();
      }
    }
  });

  return (
    <EditorContext.Provider value={editor}>{children}</EditorContext.Provider>
  );
};

const LockedEditor = () => {
  const editor = useContext(EditorContext);

  return (
    <div
      className={cn(
        'flex h-auto min-h-72 w-full flex-col rounded-md border border-input shadow-sm focus-within:border-primary'
      )}
    >
      {editor && (
        <>
          <LinkBubbleMenu editor={editor} />
          <ImageBubbleMenu editor={editor} />
        </>
      )}
      <div
        className='h-full grow'
        onClick={() => editor?.chain().focus().run()}
      >
        <EditorContent editor={editor} className='p-5' />
      </div>
    </div>
  );
};

interface LockedEditorPageProps {
  initialValue: string;
}

const LockedEditorPage = ({ initialValue }: LockedEditorPageProps) => {
  const handleValueChange = (newValue: string) => {
    console.log('Editor content updated:', newValue);
  };

  return (
    <LockedEditorProvider
      value={initialValue}
      onValueChange={handleValueChange}
    >
      <LockedEditor />
    </LockedEditorProvider>
  );
};

export default LockedEditorPage;
