import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons';
import { Markdown } from '@/components/generic/markdown';

import '@/css/display.css';
import { useDDQState } from '../use-ddq-state';
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectItem,
  SelectValue
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { SelectSeparator } from '@/components/ui/select';
import { captureEvent } from '@/lib/analytics';
import { useParams } from 'react-router-dom';
import HighlightCopy from '@/components/generic/highlight-copy';
import { CopyCitation } from '../../generic/copy-citation';
import CategoryTag from '@/components/generic/category-tag';
import { useBlankStatus } from '@/lib/is-blank';
import { MinimalTiptapEditor } from '@/components/minimal-tiptap';
import { useCallback, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { Loader2 } from 'lucide-react';
import { RerenderEditorContentHandle } from '@/components/minimal-tiptap/components/minimal-tiptap';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useAuthInfo } from '@propelauth/react';
import SearchBar from '@/components/search/use-search';
import { useEditorState } from '../use-editor-state';

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(utc);
dayjs.extend(timezone);

function formatFriendlyDate(datetimeString: string) {
  const date = dayjs.utc(datetimeString).local();
  const now = dayjs();

  if (date.isSame(now, 'day')) {
    return date.fromNow();
  } else {
    return date.calendar(null, {
      sameDay: '[today at] h:mm A',
      nextDay: '[tomorrow at] h:mm A',
      nextWeek: 'dddd [at] h:mm A',
      lastDay: '[yesterday at] h:mm A',
      lastWeek: '[last] dddd [at] h:mm A',
      sameElse: 'MMMM D, YYYY [at] h:mm A'
    });
  }
}

function convertUTCToLocal(datetimeString: string) {
  return dayjs.utc(datetimeString).local().format('MMMM D, YYYY h:mm A');
}

function useCurrentUserEmail() {
  const { user } = useAuthInfo();
  const currentUserEmail = user?.email || '';

  return function (email: string) {
    if (email === currentUserEmail) {
      return 'you';
    } else {
      return email.split('@')[0];
    }
  };
}

// eslint-disable-next-line
export const QuestionDisplay = () => {
  const {
    state: [ddqState],
    setSelectedQuestion,
    currentQuestionData,
    updateQuestionData,
    pairObj,
    pairPagination,
    answerObj: selectedAnswerObj,
    setSelectedAnswer,
    answerPagination,
    mutateCurrentEditStateWithEdit,
    // mutateCurrentEditStateWithCopy,
    currentEditState,
    isAnyEditorMutationLoading,
    // isCurrentAnswerCopying,
    // setReplacementTextConditionally
  } = useDDQState();

  const { replacementText, resetReplacementText } = useEditorState();

  const editorRef = useRef<RerenderEditorContentHandle>(null);

  useEffect(() => {
    if (replacementText.length > 0) {
      editorRef.current?.rerender(replacementText[replacementText.length - 1]);
      resetReplacementText();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replacementText]);

  const pair = pairObj ? pairObj[0] : null;

  const { current, next, prev, length } = pairPagination;

  const { uuid: ddqId } = useParams();

  const answers = pairObj && pairObj[1];
  const hasAnswers =
    typeof answers !== 'undefined' &&
    typeof answers?.length !== 'undefined' &&
    answers.length > 0;

  const selectedAnswerIndex =
    selectedAnswerObj && answers ? answers?.indexOf(selectedAnswerObj) : -1;

  const { isBlank } = useBlankStatus();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onEditorUpdate = useCallback(
    debounce(
      async (content: string) => {
        if (!pair) return;

        // debounce for 5s
        // submit
        // show updated save state
        // only on refocus of question do we fetch the latest state from the server
        console.log('editor update', pair.id, content);

        updateQuestionData(pair.id, { content });
        mutateCurrentEditStateWithEdit.mutateAsync({ content, id: pair.id });
      },
      2000,
      { maxWait: 15000 }
    ),
    [pair]
  );

  /* const onAnswerCopy = useCallback(
    async (copyPairId: string) => {
      if (!pair) return;

      console.log('answer copy', pair.id, copyPairId);

      mutateCurrentEditStateWithCopy
        .mutateAsync({ copyPairId, id: pair.id })
        .then((value) => {
          updateQuestionData(pair.id, { content: value.text || '' });
          setReplacementTextConditionally({ id: pair.id, content: value.text });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pair]
  ); */

  const displayCurrentUser = useCurrentUserEmail();

  return (
    <div className='flex max-h-full min-h-full flex-col'>
      <div className='flex items-center px-4 py-2'>
        <div className='flex items-center gap-2'>
          <h1 className='text-xl font-bold'>Selected Question</h1>
        </div>
        <div className='ml-auto flex items-center gap-2'>
          {pair && (
            <span className='text-muted-foreground'>
              {typeof current !== 'undefined' && current !== -1
                ? current + 1
                : '--'}{' '}
              of {length}
            </span>
          )}
          {!pair && <Button className='invisible' />}
          {pair && (
            <Button
              className='px-3'
              variant='outline'
              disabled={prev === undefined}
              onClick={() => {
                captureEvent('analysisClickQuestionArrow', {
                  doc_id: ddqId || '',
                  pair_id: ddqState.selectedQuestion || '',
                  direction: 'prev'
                });

                setSelectedQuestion(prev || null);
              }}
            >
              <ArrowLeftIcon />
            </Button>
          )}
          {pair && (
            <Button
              className='px-3'
              variant='outline'
              disabled={next === undefined}
              onClick={() => {
                captureEvent('analysisClickQuestionArrow', {
                  doc_id: ddqId || '',
                  pair_id: ddqState.selectedQuestion || '',
                  direction: 'next'
                });

                setSelectedQuestion(next || null);
              }}
            >
              <ArrowRightIcon />
            </Button>
          )}
        </div>
      </div>
      <Separator />
      {pair ? (
        <div className='flex flex-1 max-h-full min-h-full flex-col'>
          <div className='flex flex-[1_1_0] items-start p-4 overflow-y-scroll'>
            <div className='flex flex-col w-full items-start gap-4 text-sm'>
              {/* <Markdown
                className='markdown-els'
                content={[pair.question].join('\n\n')}
                ddqId={pair.ddq_id}
              /> */}
              <HighlightCopy copyTitle='Copy Answer' collapse>
                <Markdown
                  className='markdown-els max-w-6xl'
                  content={pair.content}
                  ddqId={pair.ddq_id}
                />
              </HighlightCopy>
              {!isBlank && (
                <>
                  <MinimalTiptapEditor
                    value={currentQuestionData?.content}
                    onValueChange={onEditorUpdate}
                    ref={editorRef}
                  />
                  <span className='self-end'>
                    {isAnyEditorMutationLoading && (
                      <Loader2
                        className={'inline-block h-4 w-4 animate-spin mr-2'}
                      />
                    )}
                    {currentEditState &&
                      currentEditState.edit_history &&
                      currentEditState.edit_history.length > 0 &&
                      currentEditState.edit_history[0] && (
                        <span
                          title={convertUTCToLocal(
                            currentEditState.edit_history[0].created
                          )}
                        >
                          {'Last edited by ' +
                            displayCurrentUser(
                              currentEditState.edit_history[0].editor.email
                            ) +
                            ' ' +
                            formatFriendlyDate(
                              currentEditState.edit_history[0].created
                            )}
                        </span>
                      )}
                  </span>
                </>
              )}
            </div>
          </div>
          {!isBlank && <Separator />}
          {!isBlank && (
            <div className='flex items-center px-4 py-2'>
              <div className='flex items-center gap-2'>
                <h1 className='text-xl font-bold'>Past Answers</h1>
              </div>
              <SearchBar searchLabel='Search the database with a different phrase...' inDDQContext />
              <div className='ml-auto flex items-center gap-2'>
                {
                  <span className='text-muted-foreground'>
                    {hasAnswers ? selectedAnswerIndex + 1 : '--'} of{' '}
                    {answers?.length || 0}
                  </span>
                }
                {!pair && <Button className='invisible' />}
                {pair && (
                  <Button
                    className='px-3'
                    variant='outline'
                    disabled={!hasAnswers || selectedAnswerIndex === 0}
                    onClick={() => {
                      captureEvent('analysisClickAnswerArrow', {
                        doc_id: ddqId || '',
                        pair_id: ddqState.selectedQuestion || '',
                        direction: 'prev',
                        index: selectedAnswerIndex
                      });

                      if (answerPagination.prev !== undefined)
                        setSelectedAnswer(answerPagination.prev);
                    }}
                  >
                    <ArrowLeftIcon />
                  </Button>
                )}
                {pair && (
                  <Button
                    className='px-3'
                    variant='outline'
                    disabled={
                      !hasAnswers || selectedAnswerIndex >= answers.length - 1
                    }
                    onClick={() => {
                      captureEvent('analysisClickAnswerArrow', {
                        doc_id: ddqId || '',
                        pair_id: ddqState.selectedQuestion || '',
                        direction: 'next',
                        index: selectedAnswerIndex
                      });

                      if (answerPagination.next !== undefined)
                        setSelectedAnswer(answerPagination.next);
                    }}
                  >
                    <ArrowRightIcon />
                  </Button>
                )}
              </div>
            </div>
          )}
          {!isBlank && <Separator />}
          {!isBlank && (
            <div className='flex flex-[1_1_0] items-start p-4 overflow-y-scroll'>
              {selectedAnswerObj && (
                <div className='flex flex-col items-start gap-4 text-sm w-full'>
                  <Select
                    value={selectedAnswerObj.pair.id}
                    onOpenChange={(open) => {
                      if (open === true)
                        captureEvent('analysisSelectAnswerDropdown', {
                          doc_id: ddqId || '',
                          pair_id: ddqState.selectedQuestion || '',
                          index: selectedAnswerIndex
                        });
                    }}
                    onValueChange={(id) => {
                      const index =
                        answers &&
                        answers.findIndex((value) => value.pair.id === id);

                      captureEvent('analysisNewAnswerSelectedFromDropdown', {
                        doc_id: ddqId || '',
                        pair_id: ddqState.selectedQuestion || '',
                        index: index !== null ? index : -1
                      });

                      setSelectedAnswer(id);
                    }}
                  >
                    <SelectTrigger
                      className={cn(
                        'flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0'
                      )}
                      aria-label='Select an answer'
                    >
                      <SelectValue placeholder='Select an answer'>
                        <span className={'font-semibold'}>
                          {selectedAnswerObj.relevancy_description}
                        </span>
                        <Separator
                          orientation='vertical'
                          className='mx-1 h-3 bg-gray-300'
                        />
                        <span className={'text-gray-500'}>
                          {selectedAnswerObj.pair.ddq.friendly_name}
                        </span>
                        <Separator
                          orientation='vertical'
                          className='mx-1 h-3 bg-gray-300'
                        />
                        <span className={'text-gray-500'}>
                          Approved on{' '}
                          {selectedAnswerObj.pair.ddq.approved_date.substring(
                            0,
                            10
                          )}
                        </span>
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent className='answer-select-content'>
                      {(answers || []).map((answer, i, allAnswers) => (
                        <div key={`${answer.pair.id}-div w-full`}>
                          <SelectItem
                            className='answer-select-item'
                            value={answer.pair.id}
                          >
                            <div className='flex flex-col gap-3 py-2'>
                              <div className='flex items-start space-x-2'>
                                <CategoryTag
                                  categoryId={answer.pair.ddq.category_id}
                                  contextCategoryId={
                                    ddqState.analysisResponse?.category_id
                                  }
                                />
                                {/* <Markdown
                                  className=''
                                  content={answer.pair.question}
                                  ddqId={answer.pair.ddq_id}
                                /> */}
                              </div>

                              <Markdown
                                className='pl-2 max-h-16 overflow-y-hidden text-gray-500 border-l-2'
                                content={answer.pair.content}
                                ddqId={answer.pair.ddq_id}
                              />
                              <div className='flex text-gray-400'>
                                <span>{answer.relevancy_description}</span>
                                <span className='flex-grow'></span>
                                <span>{answer.pair.ddq.friendly_name}</span>
                                <Separator
                                  orientation='vertical'
                                  className='mx-2 mt-1 h-3 bg-gray-300'
                                />
                                <span>
                                  {answer.pair.ddq.approved_date.substring(
                                    0,
                                    10
                                  )}
                                </span>
                              </div>
                            </div>
                          </SelectItem>
                          {i < allAnswers.length - 1 && <SelectSeparator />}
                        </div>
                      ))}
                    </SelectContent>
                  </Select>
                  <div className='flex w-full items-start space-x-2'>
                    <CategoryTag
                      categoryId={selectedAnswerObj.pair.ddq.category_id}
                      contextCategoryId={ddqState.analysisResponse?.category_id}
                    />
                    {/* <Markdown
                      className='markdown-els'
                      content={[selectedAnswerObj.pair.question].join('\n\n')}
                      ddqId={selectedAnswerObj.pair.ddq_id}
                    />
                    <div className='flex-grow flex justify-end gap-2 mb-8'>
                      {selectedAnswerObj.pair.ddq.approved_date &&
                        (isCurrentAnswerCopying ? (
                          <ButtonLoading>Copy to Editor</ButtonLoading>
                        ) : (
                          <Button
                            onClick={() =>
                              onAnswerCopy(selectedAnswerObj.pair.id)
                            }
                          >
                            Copy to Editor
                          </Button>
                        ))}
                    </div> */}
                  </div>

                  {selectedAnswerObj.pair.ddq.approved_date && (
                    <>
                      <CopyCitation
                        friendlyName={selectedAnswerObj.pair.ddq.friendly_name}
                        approvedDate={selectedAnswerObj.pair.ddq.approved_date.slice(
                          0,
                          10
                        )}
                      />
                    </>
                  )}
                  <HighlightCopy
                    copyTitle='Copy Answer'
                    collapse={!selectedAnswerObj.pair.ddq.approved_date}
                  >
                    <Markdown
                      className='markdown-els'
                      content={selectedAnswerObj.pair.content}
                      ddqId={selectedAnswerObj.pair.ddq_id}
                    />
                  </HighlightCopy>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className='p-8 text-center text-muted-foreground'>
          No question selected
        </div>
      )}
    </div>
  );
};
